<template>
  <div>
    <Animation />
    <Content />
    <Cases />
    <Contact />
  </div>
</template>

<script>
import Animation from "@/components/Animation.vue";
import Content from "@/components/Content.vue";
import Cases from "@/components/Cases.vue";
import Contact from "@/components/Contact.vue";
export default {
  name: "Home",
  components: {
    Animation,
    Content,
    Contact,
    Cases,
  },
};
</script>
