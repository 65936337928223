<template>
  <div>
    <TheMenu />
    <Home />
  </div>
</template>

<style lang="scss">
// Import Google fonts
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap");
* {
  background: #e6273e;
  font-family: "Montserrat", sans-serif;
  color: white;
}
@media screen and (max-width: 550px) {
  * {
    background: #d72537;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 15px !important;

  font-weight: 600;
}
p {
  font-weight: 300;
  font-size: 15px;
}
.navbar-toggler-icon {
  background-image: url("../public/hamburger.svg") !important;
}
</style>

<script>
import Home from "@/views/Home.vue";
import TheMenu from "@/components/TheMenu.vue";
export default {
  name: "App",
  components: {
    Home,
    TheMenu,
  },
};
</script>
