<template>
  <section id="content">
    <b-container>
      <!-- Title -->
      <b-row>
        <b-col md="12">
          <div class="text-center">
            <h1>360° mit Webrepublic</h1>
          </div>
        </b-col>
      </b-row>

      <!-- Article text-->
      <b-row class="article-text">
        <b-col offset-md="1" md="10">
          <h1 class="text-center font-weight-light">
            Wir verlängern Strategien, die digital funktionieren, in die
            klassischen Medien. Dabei nutzen wir digitale Potenziale und
            reduzieren die Komplexität, um sie auf alle Medien auszuweiten. So
            verknüpfen wir beide Welten – vom ersten TV-Spot bis zur digitalen
            Customer Journey auf Search, Social und der Website.
          </h1>
        </b-col>
      </b-row>

      <!-- Grids -->
      <b-row>
        <b-col class="mb-5" offset-md="1" md="10">
          <div class="text-center">
            <h4>Integrierte Konzepte</h4>
            <p>
              Herausragende Kampagnen entstehen an der Schnittstelle zwischen
              Strategie, Technologie, Media und Kreation. Um Konzepte zu
              erstellen, die wirklich ineinandergreifen und alle Abhängigkeiten
              berücksichtigen, arbeiten wir im Co-Creation-Modus:
              Spezialistinnen und Spezialisten aus allen Bereichen arbeiten
              iterativ an einem gemeinsamen Verständnis von Zielen, Massnahmen
              und Potenzialen.
            </p>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="mb-5" offset-md="1" md="10">
          <div class="text-center">
            <h4>Digitale Daten für bessere Planung</h4>
            <p>
              Wir messen die Wirkung von klassischen Medien auf digitale KPIs:
              Wie verändert sich das Suchvolumen von Marken und Produkten
              während eines Plakat-Flight? Wie verhalten sich durch eine
              TV-Kampagne neu gewonnene Userinnen und User auf der Website?
              360°-Media-Dashboards geben in Echtzeit Aufschluss über die
              Gesamtperformance.
            </p>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="mb-5" offset-md="1" md="10">
          <div class="text-center">
            <h4>Programmatische Buchung</h4>
            <p>
              Real-Time-Bidding ist auf dem Vormarsch. Was digital längst
              etabliert ist, verbreitet sich auch in der physischen Welt:
              Digitale Aussenflächen können in Echtzeit angesteuert werden, TV
              und Radio werden zunehmend programmatisch. So erreichen wir über
              alle Medien hinweg die richtigen Kunden zum richtigen Zeitpunkt,
              können flexibel auf Marktentwicklungen reagieren und bewegen uns
              hin zu agilen Mediaplänen.
            </p>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="mb-5" offset-md="1" md="10">
          <div class="text-center">
            <h4>Digitale und klassische Kreation</h4>
            <p>
              Wir analysieren digitale Performancedaten für die Verbesserung der
              Kampagnenstrategie: Welche Bewegtbild-Werbemittel zeigen auf
              YouTube den grössten Consideration-Uplift? Welche Botschaften
              konvertieren auf Social Media am besten? Diese Daten setzen wir
              ein, um Sujets für Plakate, digitale Screens und TV zu optimieren.
            </p>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<style lang="scss" scoped>
#content {
  padding-top: 0px;
}
.article-text {
  margin-bottom: 50px;
}
h1 {
  font-size: 1.8rem !important;
  font-weight: 600;
}
h4 {
  font-weight: 600;
}
</style>

<script>
export default {
  name: "Content",
};
</script>
